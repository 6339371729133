.img-container {
  padding: 0;
  animation : shimmer 1.7s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #d8d7d7 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.image {
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: block;
}

.visible {
  visibility: visible;
}