.projects-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.filter-box {
  background-color: #444444;
  padding: 0 1.5rem 12px;
  border-radius: 5px;
  transition: all 0.25s ease-out;
  max-height: 45px;
  overflow: hidden;
}

.filter-box.visible {
  max-height: 425px;
}

.filter-box-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
}

.filter-count {
  color: #f8b4b4;
  font-weight:100;
  margin-left: 15px;
}

.filter-text {
  font-size: 1.2rem;
  margin-left: 15px;
  flex: 1;
}

.filter-toggle-btn {
  transform: rotate(180deg);
  transition: all 0.3s ease-out;
}

.filter-toggle-btn.open {
  transform: rotate(360deg);
}

.filter-toggle-btn svg {
  width: 25px;
  height: 25px;
  fill: #CCCCCC;
}

.project {
  width: calc(100%-3rem);
  background-color: #444444;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
}

.row1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.details {
  width: 45%;
  font-size: 0.9rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  flex: 1 1 300px;
}

.project h1 {
  margin: 0;
  padding: 0px;
  margin-bottom: 0.5rem;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0rem;
  font-size: 1rem;
}

.links a {
  text-decoration: none;
}

.link {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px;
  background: linear-gradient(to top, #a84dc7 calc(50% + 4px), transparent calc(50% - 4px));
  background-size: 100% 200%;
  background-position: top left;
  border-radius: 4px;
  transition: all .25s ease-out;
}

.link:hover {
  background-position: bottom left;
  filter: brightness(120%)
}

.screen {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 300px;
}

.proj-screenshot {
  max-width: 98%;
  max-height: 400px;
}

.tech-stack {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-top: 8px;
}

.project h3 {
  margin: 0 0 5px 0;
}


.tech-stack-item {
  background-color: #CCCCCC;
  color: #111111;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tech-stack-item svg {
  fill: black;
  margin-right: 6px;
  height: 1.5rem;
}

.filter-button {
  cursor: pointer;
  font-size: 0.8rem;
}

.filter-button svg {
  height: 1rem;
}

.filter-button img {
  height: 1rem;
  margin-left: 6px;
}

.filter-button:hover {
  background-color: #FFFFFF;
}

.filter-list-inactive {
  padding-bottom: 10px;
}

@media screen and (max-width: 500px) {
  .filter-button {
    padding: 3px 6px;
    margin-right: 4px;
    margin-bottom: 4px;
  }
}