.posts-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-template-rows: auto;
  grid-auto-rows: auto;
  grid-gap: 30px;
  justify-content: center;
  margin-bottom: 55px;
}

.posts-container a {
  text-decoration: none;
}

.post {
  display: flex;
  justify-content: center;
  background-color: #444444;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.post:hover {
  filter: brightness(110%);
}

.post-image {
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  min-height: 200px;
}

.post-body {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.post-description {
  flex-grow: 1;
}

.post-bottom {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
}

.post-tags-list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.post-tag {
  background-color: #CCCCCC;
  color: #111111;
  padding: 1px 10px;
  margin-left: 4px;
  margin-bottom: 4px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tags-container {
  display: flex;
  flex-direction: row;
}

