html {
  -webkit-tap-highlight-color: rgba(255,255,255, 0.2)
}

body {
  margin: 0;
}

#root {
  font-family: 'Lexend', sans-serif;
  color: #DDDDDD;
  background-color: #222222;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

#root a {
  color: #DDDDDD;
}

.page-content {
  padding: 15px 1.5rem;
  margin-top: 115px;
  background-color: #333333;
  z-index: 0;
  flex-grow: 5;
}

.page-content.sm-margin {
  margin-top: 50px;
  /* min-height: calc(100dvh - 80px); */
}

.footer {
  background-color: #222222;
  padding: 15px 15px;
}

.footer-content {
  font-size: 0.8rem;
}

.footer-content a {
  color: #CCCCCC;
  padding-left: 1rem;
  padding-right: 1rem;
}

